import { derived, writable, type Writable } from 'svelte/store';
import type { Countdown } from './types';
import { browser } from '$app/environment';
import { PUBLIC_PROMO_LABEL_START_DATE, PUBLIC_PROMO_LABEL_END_DATE } from '$env/static/public';

export class CountdownStore {
  private static _instance: CountdownStore;

  private _startDate: Date;
  private _endDate: Date;

  private _checkInterval: number;
  private _countdownInterval: number;

  private _countdown: Writable<Countdown | null>;
  private _status: 'visible' | 'hidden';

  private constructor() {

    this._countdown = writable(null);
    this._checkInterval = 0;
    this._status = 'hidden';
    this._countdownInterval = 0;
    this._startDate = new Date();
    this._endDate = new Date();

    if (browser) {

      const START_DATE = PUBLIC_PROMO_LABEL_START_DATE;
      const END_DATE = PUBLIC_PROMO_LABEL_END_DATE;

      if (START_DATE && END_DATE && new Date(START_DATE) < new Date(END_DATE)) {
        this._startDate = new Date(START_DATE);
        this._endDate = new Date(END_DATE);

        const now = new Date();

        if (now <= this._endDate) {
          if (this._startDate >= now) {
            this.startChecking();
          } else if (this._startDate <= now) {
            this.startCountdown();
          }
        } else {
          this._countdown.set(null);
        }
      }
    }
  }

  private startChecking() {
    this._checkInterval = window.setInterval(() => {
      const now = new Date();
      if (this._startDate <= now) {
        this.startCountdown();
        this.clearIntervalSafe(this._checkInterval);
      }
    }, 60000);
  }

  private startCountdown() {
    this._countdownInterval = window.setInterval(() => {
      const now = new Date();
      if (now >= this._endDate) {
        this._countdown.set(null);
        this.clearIntervalSafe(this._countdownInterval);
      } else {
        this._countdown.set(this.calculateCountdown());
      }
    }, 1000);
  }

  private clearIntervalSafe(interval: number) {
    if (interval) {
      clearInterval(interval);
    }
  }

  private calculateCountdown(): Countdown {
    const distance = this._endDate.getTime() - new Date().getTime();
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  public static getInstance() {
    if (!CountdownStore._instance) {
      CountdownStore._instance = new CountdownStore();
    }
    return CountdownStore._instance;
  }

  public get countdown() {
    return derived(this._countdown, (value) => value);
  }

  public get displayLabel() {
    return derived(this._countdown, (value) => value !== null);
  }
}
